.alert {
    @include alert-box;
	margin-top: 1em;
	margin-bottom: 1em;

	&.alert-margin {
		margin-bottom: 1em;
	}

	&.small {
		padding: 0.8em;
		font-size: 0.9em;
	}

	&:before {
		@include font-awesome("");
		margin-right: .5em;
	}

	&.alert-warning,
	&.alert-error {
		background-color: red;
		color: white;
		a { color: white; text-decoration: underline; }
		&:before {
			@include font-awesome("");
		}
	}

	&.alert-warning {
		background-color: $color-orange;
	}
}

.stock-warning {
    padding: 1em;
    border: 5px dashed $color_grey_light;
    margin-bottom: 2rem;

    p:first-child {
        margin: 0;
    }

    h3 {
        margin-top: 0;
        color: red;
    }

    h4 {
        font-size: 12px;
    }

    ul {
        margin: .5rem 0;
        padding: 0 0 0 17px;

        li { font-weight: bold; }
    }

    .box-actions {
        margin-top: 1em;
        border-top: 1px solid $color_grey_light;
        padding: 1.5em 0 .5em 0;

        .small-button {
            border-radius: 3px;
            background-color: $color_grey_light;
            color: black;
            padding: .5em 1em;

            &:hover {
                background-color: darken($color_grey_light, 10%);
            }
        }
    }
}

.member-notification {
    .member-notification-inner {
        @include alert-box;

        margin-top: 1em;
        margin-bottom: 1em;
        position: relative;

        .close-icon {
            position: absolute;
            top: 8px;
            right: 15px;
            font-size: 1.5em;
            transition: $transition-base;

            &:hover {
                color: black;
            }
        }
    }
}
