.block {
    &.block-region-below_title {
        font-size: .80rem;
        margin-bottom: 2rem;

        *:first-child {
            margin-top: 0;
        }
        *:last-child {
            margin-bottom: 0;
        }
    }

    &.message {
        margin-bottom: 1.5rem;

        &:first-child {
            margin-top: .5rem;
        }

        .lists-index &:first-child,
        .productsindex-index &:first-child {
            margin-top: 0;
        }

        .pages-home &:first-child {
            margin-top: 1.5rem;
        }

        background-color: $color_grey_light;
        padding: 1.5rem;
        border-radius: 5px;

        *:first-child {
            margin-top: 0;
        }
        *:last-child {
            margin-bottom: 0;
        }

        &.block-info {
            display: flex;

            &:before {
                @include font-awesome($fa-var-info-circle);
                margin-right: 10px;
            }
        }

        &.block-alert {
            display: flex;
            background-color: lighten($color_orange, 40%);

            &:before {
                @include font-awesome($fa-var-exclamation-triangle);
                margin-right: 10px;
            }
        }
    }
}
