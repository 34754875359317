// BS GRID
$enable-grid-classes: false;
$enable-container-classes: false;
$enable-cssgrid: true;

// COLORS
$color-text: #000;
$color_orange: #ee981f;
$color_orange_hover: darken($color_orange, 15%);
$color_green: #748f3e;
$color_dark: #2b2b2b;
$color-brown: #68471c;
$color_grey_light: #eeeeee;
$color_grey_light_2: lighten($color_grey_light, 5%);
$color-promo: darken($color_orange, 20%);
$color_grey_dark: #666;

// OTHERS
$transition-base: all .2s ease-in-out;

// FONTS
$fa-version: "5.9.0-hs";
$fa-font-path: "/fonts";
$base-font-size: 85%;
$base-line-height: 1.6;
$heading-line-height: 1.2;

// NEAT
$max-width: 1200px;

// BREAKPOINTS
$mobile-small: new-breakpoint(max-width 360px 4);
$mobile: new-breakpoint(max-width 650px 4);
$portrait: new-breakpoint(max-width 768px 4);
$tablet: new-breakpoint(max-width 1024px 8);
$desktop: new-breakpoint(min-width 650px 12);
$desktop-medium: new-breakpoint(min-width 768px 12);
$desktop-large: new-breakpoint(min-width 1100px 12);


$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

// FLEX GRID
$flex-gutter-width: 20px;
