@keyframes bounce {
	0% {
	    padding-right: 0;
	}
	50% {
	    padding-right: 0.3em;
	}
	100% {
		padding-right: 0;
	}
}
