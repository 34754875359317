
.stikcy-filter-toggle {
    display: none;
    position: sticky;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    text-align: center;
    background-color: $color_dark;
    color: white !important;
    margin: $gutter -10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2em;

    &:hover {
        background-color: darken($color_dark, 5%);
    }

    @include media($portrait) {
        display: block;
    }
}
#facet-wrapper {
    .facet-title {
        display: none;
    }

    @include media($portrait) {
        display: none;
        position: fixed;
        padding: $gutter;
        padding-top: 60px;
        padding-bottom: 70px;
        z-index: 99;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        overflow-y: auto;

        .facet-title {
            display: block;
            text-align: center;
        }

        &.visible {
            display: block;
        }
    }
}


.facet {
	box-shadow: 0 0 6px rgba(0,0,0,0.1);
	padding: 1rem;
	border-radius: 5px;
	margin-bottom: 2em;

	.more-wrapper {
		text-align: right;
		font-weight: bold;
		border-top: 1px solid #eee;
		margin-top: .5rem;
		padding-top: .5em;
		text-transform: lowercase;
	}

	//overflow: hidden;
	h3 {
		margin: 0;
		padding: 0;
		text-transform: uppercase;
		font-size: 1.1em;
        margin-bottom: 1rem;
	}

	ul > li.hidden {
		display: none;
	}

	&.expanded ul > li.hidden {
		display: block;
	}

	ul {
		@include clear-list;

		li {
			padding: 3px 0;
			font-size: 0.85rem;

            .facet-content {
                display: flex;
                align-items: center;
                width: 100%;
            }

			.count {
				background-color: #eeeeee;
				font-size: 0.8em;
				padding: 1px 5px;
				border-radius: 7px;
				margin-left: auto;
			}

			a {
				color: #000;
                display: flex;
			}

            a:before {
                content: "";
                border: 1px solid black;
                margin-top: 4px;
                width: 13px;
                height: 13px;
                border-radius: 3px;
                display: block;
                margin-right: 5px;
            }

            a.active {
                font-weight: bold;

                &::before {
                    background-color: black;
                }
            }
		}
	}
}
