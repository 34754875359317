
@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400italic,600italic,700italic,600,400");
@import url("/components/fancybox/source/jquery.fancybox.css");

@import 'normalize';
@import "bourbon";
@import "neat";
@import "variables";


// 3th party components
@import '3thparty/autocomplete';
@import '3thparty/fontawesome';
@import 'sass-burger/burger';
@import 'bootstrap/scss/bootstrap-grid';

@import 'base';
@import "mixins";
@import "burger";
@import "animations";
@import "numberpolyfill";
@import "signup";
@import 'form';
@import 'facets';
@import 'messages';
@import 'block';

body {
	font-family: 'Open Sans', sans-serif;
	font-size: $base-font-size;
	margin: 0;
	padding: 0;
	line-height: $base-line-height;
	color: $color-text;

    &.filter-overlay-visible,
	&.mobile-nav-active {
		overflow: hidden;
	}
}

.content-container {
	@include outer-container;
	padding: 0 10px;
}

.pull-left { float: left; }
.pull-right { float: right; }


.slicknav_menu {
	display: none;
	background-color: $color_orange;
	a { color: #fff; }

	.slicknav_btn {
	    background: none;
	    .slicknav_menutxt { display: none; }
	    .slicknav_icon {
	        .slicknav_icon-bar { display: none; }
	        @include burger(25px, 5px, 3px, #ffffff, 4px, .5s);
	    }
	    &.slicknav_open .slicknav_icon  {
	        @include burger-to-cross();
	    }
	}
}

@include media($portrait) {
	#main-navigation {
		display: none;
	}
	.slicknav_menu {
		display: block;
	}
}

#mobile-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transform: translateY(-100%);
	background: rgba($color_orange, 0.95);
	z-index: 998;
	transition: transform 150ms ease-in-out;
	&.active {
		transform: translateY(0);
	}
	.content {
		padding-top: 50px;
		overflow: scroll;
		height: 100%;
	}

	.service-navigation,
	.main-navigation {
		@include clear-list;
		> li {
			a {
				color: white;
				display: block;
			}
			> a {
				&:after {
					@include font-awesome($fa-var-chevron-down);
					float: right;
					margin-top: 0.1em;
				}
				padding: 0.7em;
				border-bottom: 1px solid;
				font-size: 1.5em;
			}
			.dropdown-menu {
				@include clear-list;
				display: none;
			}

			&.active {
				> a:after {
					@include font-awesome($fa-var-chevron-right);
				}
				.dropdown-menu {
					display: block;
					font-size: 1.3em;
					padding: 0.7em;
					> li {
						padding: 0.2em 0;
					}
				}
			}
		}
	}

	.service-navigation {
		> li {
			&.burger,
			&.icon { display: none; }
		}
	}
}

header {
    @include clearfix;
	background-color: #fff;
	padding-top: 50px;

	.service-bar {
		background: $color_dark;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
		border-bottom: 1px solid lighten($color_dark, 30%);
		position: fixed;
		z-index: 1500;
		z-index: 999;
		top: 0;
		right: 0;
		left: 0;
		.current-master-name {
			float: left;
			color: white;
			font-weight: bold;
			padding: 12px;
			font-size: 1em;
			@extend .member-icon;
			&:before { margin-right: 1em; }
		}
		.service-navigation {
			@include clear-list;
			float: right;
			> li {
				position: relative;
				float: left;
				> a {
					display: inline-block;
					padding: 12px;
					color: lighten($color_dark, 40%);
					span { display: none; }
				}

				@include media($mobile) {
					&.item { display: none; }
				}

				&.has-subnav {
					color: white;
					> a:after {
						@include font-awesome("");
						margin-left: 0.5em;
					}
					&.active {
						background: black;
						> .subnav {
							display: block;
						}
					}
				}

				&.burger {
					display: none;
					padding: 14px;
					padding-right: 0;

					> a {
						padding: 0;
						@include burger(25px, 2px, 7px, #fff);

						&.is-active {
							@include burger-to-cross();
						}
					}

					@include media($mobile) {
						display: block;
					}
				}

				&.icon {
					> a {
						padding: 12px 20px;
						color: white;
						font-size: 1.2em;
						border-right: 1px solid lighten($color_dark, 20%);
						position: relative;
					}

					&.icon-first {
						margin-left: 0.5em;
						> a {
							border-left: 1px solid lighten($color_dark, 20%);
						}
					}
				}

				&.cart-order > a,
				&.cart-wishlist > a {
					&.show-count span { display: block; }
					span {
						$size: 17px;
						display: none;
						width: $size;
						height: $size;
						line-height: 15px;
						text-align: center;
						border-radius: 10px;
						background: $color-dark;
						border: 1px solid white;
						font-size: 10px;
						position: absolute;
						right: 7px;
						bottom: 9px;
					}
				}

				&.user > a:before { @include font-awesome($fa-var-user); }
				&.cart-order a:before { @include font-awesome($fa-var-shopping-cart); }
				&.cart-wishlist a:before { @include font-awesome($fa-var-heart); color: $color-orange; }

				> .subnav {
					display: none;
					@include clear-list;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 49px;
					right: 0;
					background: white;
					min-width: 230px;
					border: 1px solid $color_dark;
					border-top: none;
					padding: 0.5em 1em;
					> li {
						color: black;
						padding: 0.2em 0;
						cursor: pointer;

						a {
							color: black;
							margin: 0;
							padding: 0;
						}

						&.sublist {
							margin: 0.5em 0 1em 0;
							border-bottom: 1px solid $color_grey_light;
							.label {
								text-transform: uppercase;
								font-weight: bold;
								border-bottom: 1px solid $color_grey_light;
								font-size: 0.8em;
								margin-bottom: 0.5em;
								padding: 0.5em 0;
								margin-bottom: 0.5em;
								> a { color: $color_orange; }
							}
							> ul {
								@include clear-list;
								> li {
									@extend .member-icon;
									padding-right: 1.5em;
									&.not-approved {
										opacity: 0.3;
									}

									&.active-master {
										color: $color-orange;
										position: relative;
										&:after {
											@include font-awesome("");
											position: absolute;
											right: 0;
											top: 50%;
											transform: translateY(-50%);
										}
									}

									padding: 0.3em 0;
									&:before {
										margin-right: 0.5em;
										min-width: 18px;
									}

									.org {
										padding-left: 25px;
										font-size: 0.7em;
										color: #999;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.top {
		@include row;
		.logo-wrapper {
			@include span-columns(3);
			.logo {
				@include hide-text;
				background: url('../img/logo.svg') no-repeat center center;
				background-size: 100%;
				display: block;
				width: 130px;
				height: 94px;
				margin: 10px 0 27px 0;
			}
		}

		.service-sub {
			@include span-columns(9);
			@include row;
			padding-top: 40px;
            display: flex;
            align-items: center;
            justify-content: right;

			.cta {
                display: none;
				margin-right: 1em;
				font-weight: 600;
                font-size: 1.2em;
				padding-top: 5px;
                transition: $transition-base;
				color: $color_orange;

                .fa {
                    font-size: 6px;
                    vertical-align: middle;
                    margin: 0 8px;
                }

                a {
                    color: $color_orange;

                    &:hover {
                        color: $color_orange_hover;
                    }
                }

                @include media($desktop-large) {
                    display: flex;
                    align-items: center;
                }
			}

            .autoComplete_wrapper {
                width: 100%;
            }

			.search {
				position: relative;
				.input { padding: 0; }

				input {
					padding: 12px;
                    width: 250px;
					margin: 0;
                    transition: width .2s ease-in-out;

                    @include media($desktop-medium) {
                        &:focus {
                            width: 350px;
                        }
                    }
				}

				button {
					display: block;
					position: absolute;
					right: 0;
					bottom: 0;
					height: 100%;
					background-color: #e0e0e0;
					color: #000;
					&:focus {
						background-color: darken(#e0e0e0, 10%);
					}
				}
			}
		}

		@include media($mobile) {
			.service-sub {
				padding-top: 15px;
			}
		}

		@include media($portrait) {
			.logo-wrapper {
				@include span-columns(4);
				.logo {
					margin: $gutter auto 0 auto;
				}
			}
			.service-sub {
				@include span-columns(4);
				.search  {
                    input {
					    width: 100%;
                    }
					margin-bottom: $gutter;
				}
			}
		}

	}
}

nav#main-navigation {
	position: relative;

	.main-navigation {
		@include clear-list;
		@include clearfix;
		width: 100%;
		background: $color_orange;
		display: table;
		white-space: nowrap;
		> li {
			display: table-cell;
			white-space: normal;
			&.active a {
				background-color: darken($color_orange, 10%) !important;
			}
			> a {
				display: block;
				border-right: 1px solid darken($color-orange, 10%);
				text-align: center;
				display: block;
				color: #fff;
				text-transform: uppercase;
				padding: 24px;
				font-weight: bold;
				transition-duration: 150ms;
				&:hover { background-color: darken($color_orange, 5%); }
				&.professionals {
					background: darken($color_dark, 15%);
				}
				&.active {
					background-color: darken($color_orange, 5%);
				}
			}

			.products span:after,
			&.dropdown span:after {
				margin-left: 0.5em;
				color: #fff;
				@include font-awesome($fa-var-chevron-down);
			}
			.products.active span:after {
				@include font-awesome($fa-var-chevron-up);
			}
		}
		li.dropdown {
			position: relative;
			&:hover ul.dropdown-menu {
				display: block;
			}
			ul.dropdown-menu {
				display: none;
				@include clear-list;
				z-index: 999;
				background: $color-orange;
				position: absolute;
				width: 100%;
				> li a {
					color: #fff;
					display: block;
					padding: 0.8em 0.5em;
					border-top: 1px solid darken($color-orange, 5%);
					&:hover { background-color: darken($color_orange, 5%); }
				}
			}
		}
	}

	.doormat {
		@include row;
		position: absolute;
		left: 0;
		top: 5em;
		width: 100%;
		z-index: 700;
		display: none;
		background: #fff;
		box-shadow: 0px 2px 6px rgba(0,0,0,0.3);
		overflow: hidden;
		&.open {
			display: block;
			transition: height 80ms linear;
			.placeholder span:before {
				animation: bounce 1s infinite;
			}
		}

		ul {
			@include clear-list;
		}

		.placeholder {
			@include span-columns(9);
			font-size: 3em;
			font-weight: 300;
			text-align: center;
			padding-top: 4.4em;
			color: $color_grey_light;

			span:before {
				@include font-awesome("");
				margin-right: 20px;
				font-size: 1.4em;
				vertical-align: top;
			}
		}

		.groups {
            @include span-columns(3);

			height: 100%;
			border-right: 1px solid $color_grey_light;
            padding: 1rem 0;

			> li > a {
				color: #000;
				display: block;
				font-size: 1.1em;
				padding: 0.3em 1em;
				font-weight: 600;
			}

			> li.active > a {
				background: $color-orange;
				color: white;
			}

			li:last-child a { border: none; }

			li > .doormat-sub {
				padding: $gutter 0.5em;
				display: none;
				position: absolute;
				top: 0;
				left: flex-grid(3) + flex-gutter(12);
				width: flex-grid(9);
				right: 2em;
			}

			.category-box {
				width: flex-grid(4);
				padding-bottom: $gutter;

				a {
					color: $color-orange;
					transition: color ease-in 150ms;
					&:hover {
						color: darken($color-orange, 20%);
					}
				}

                .label a {
                    color: black;
                }

				.label {
					text-transform: uppercase;
					font-weight: bold;
					padding-bottom: .5em;
				}

                label::after {
                    margin-left: 0.8em;
                }
			}
		}
	}
}

#main {
	width: 100%;
	min-height: 500px;
	@include media($desktop) {
        padding-bottom: 50px;
        min-height: calc(100vh - 543px);
	}
}

.slideshow-wrapper {

	@include media($mobile) {
		//display: none;
	}

	position: relative;
	.slideshow-controls-buttons .slideshow-next,
	.slideshow-controls-buttons .slideshow-prev {
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		z-index: 600;
		color: white;
		font-size: 3em;
		span { display: none; }
	}
	.slideshow-controls-buttons .slideshow-prev {
		left: 20px;
		&:before { @include font-awesome($fa-var-chevron-left); }
	}
	.slideshow-controls-buttons .slideshow-next {
		right: 20px;
		&:before { @include font-awesome($fa-var-chevron-right); }
	}

	.slideshow-pager {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 600;
		color: white;
		ul {
			@include clear-list;
		}
		li {
			display: inline;
			a {
				display: inline-block;
				width: 30px;
				height: 30px;
				border-radius: 15px;
				background: rgba(#FFFFFF, 0.5);
				text-align: center;
				line-height: 30px;
				margin: 0 3px;
				color: black;
			}
			&.cycle-pager-active a {
				background: rgba(#FFFFFF, 1);
			}
		}
	}
}

#banners {
	overflow: hidden;
	width: 100%;
	position: relative;

	&.inline { margin-bottom: 20px; }

	.banner {
		position: relative;
		width: 100%;

		.title {
			font-weight: bold;
			max-width: 50%;
			position: absolute;
			right: 20px;
			text-shadow: 0 0 3px rgba(0,0,0,0.4);
			top: 20px;
			color: #fff;
			text-transform: uppercase;
			font-size: modular-scale(3, $base-font-size);
			text-align: right;
		}
		@include media($mobile) {
			.title {
				text-align: right;
				left: 25px;
				right: 25px;
				font-size: 20px;
				max-width: 100%;
			}
		}

        picture,
        img {
            width: 100%;
        }
	}
}


.blocks.region-content {
	padding: 30px 0 0 0;
	@include clearfix;

	@include media($mobile) {
		padding-top: $fg-gutter;
	}

	.block-region-content {
		&.block-hidden-mobile {
			@include media($mobile) {
				display: none;
			}
		}
		@include media($desktop) {
			@include span-columns(4);
			@include omega(3n);
		}
		@include media($mobile) {
			margin-bottom: $fg-gutter;
		}
		position: relative;
		h3 {
			position: absolute;
			top: 0;
			left: 0;
			color: #fff;
			margin: 0;
			padding: 25px;
			font-size: modular-scale(2, $base-font-size);
			text-shadow: 1px 1px 4px rgba(0,0,0,0.8);
		}
		img {
			width: 100%;
		}
		.content {
			position: absolute;
			bottom: 1em;
			left: 25px;
			color: white;
			a {
				@extend .button;
			}
		}
	}
}

footer {
	background: url('../img/bg2.jpg') repeat-x center top;

	.footer-top {
		display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        #gmap,
		.block {
			width: 30%;
			padding: 2em 0;
		}

		#gmap {
            padding: 0;
			margin: 1em 0;
			min-height: 200px;
			border: 1px solid #ccc;
			border-radius: 8px;
            min-height: 250px;
		}

		@include media($tablet) {
			.block {
                width: 49%;
			}

            #gmap {
                width: 100%;
            }
		}

		@include media($mobile) {
			.block {
                width: 100%;
			}
		}
	}

	.footer-sub {
		@include clearfix;
		background-color: $color-dark;
		padding: 20px 30px;
		color: lighten($color_dark, 40%);
		a {
			color: lighten($color_dark, 40%);
			//padding: 0 8px;
			&:hover { color: #fff; }
		}
		.social {
			padding: 0 0 0 25px;
			a:hover { color: white; }
			a {
				padding: 0 0 5px 0;
				font-size: 1.2em;
				vertical-align: middle;
			}
		}
		.legal {
			ul {
				@include clear-list;
				display: inline;
				padding-right: 0.5em;
				> li {
					font-size: 0.8em;
					padding: 0 0.5em;
					display: inline-block;
				}
			}
		}

		@include media($tablet) {
			padding: 10px;
			text-align: center;
			.pull-left {
				text-align: center;
				float: none;
				padding: 0.5em 0 0 0;
			}
			.legal {
				float: none;
				ul {
					padding: 0.5em 0 1em 0;
					display: block;
					text-align: center;
					> li { padding: 0 0.2em; }
				}
			}
		}
	}
}

.grid-col-4 {
    @include flex-row();

	.col {
        @include flex-columns-ready();
        @include flex-columns(4);

        margin-bottom: $flex-gutter-width;
		border: 1px solid #ccc;
		position: relative;

        @include media($tablet) {
            @include flex-columns(2);
        }

		.overlay {
			color: #fff;
			background-color: rgba(0,0,0,0.5);
			position: absolute;
			bottom: 10px;
			left: 10px;
			right: 10px;
			padding: 15px;
			a { color: #fff; }
		}

		h3 {
            margin: 0;
            padding: 0;
        }

		.image {
			overflow: hidden;
			img {
				width: 100%;
				transition: transform ease-out 100ms;
				&:hover { transform: scale(1.05); }
			}
		}

		.info-below h3 {
            font-weight: normal;
            font-size: 1em;
		}

		&.product {
			background: #e9e9e9;
		}

		&.with-share {
			padding: 0;
			border: none;
			.inner {
				//padding: 10px 10px 0 10px;
				border: 1px solid #ccc;
				border-bottom: none;
			}
		}
		.category-navigation {
			padding: 10px;
			border-top: 1px solid #eee;

			&.single {
				min-height: 60px;
			}

            h3 {
                margin-bottom: 5px;
            }

			h2 {
				color: $color-brown;
                font-size: modular-scale(1, $base-font-size);
				text-transform: uppercase;
				margin-bottom: 0.5em;
			}

			ul {
				@include clear-list;
				margin: 0 0 0 0;
				min-height: 110px;
				li {
					&:before {
						@include font-awesome("");
						margin-right: 0.4em;
					}
				}
			}
			a {
				color: $color-brown;
				&:hover { color: lighten($color-brown, 20%); }
			}

			.inner {
				min-height: 160px;
			}

			@include media($tablet) {
				&.single { min-height: auto; }
			}
		}
		&.compact .inner {
			padding: 1em;
			min-height: auto;
		}
		.more {
			text-align: right;
			font-size: 0.9em;
		}
	}
}

.info-share-bar {
	@include clearfix;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	padding: 10px;
	color: #fff;
	background-color: $color_dark;
	.date { float: left; font-size: 0.9em; }
	.action {
		float: right;
		a {
			color: #fff;
			text-transform: uppercase;
			&:hover { opacity: 0.5; }
			i { font-size: 1.3em; vertical-align: middle; margin-right: 10px; }
		}
	}
}

.content.has-sidebar {
	@include clearfix;
    padding-top: 1em;

	.sidebar { @include span-columns(3); }
	.inner-content { @include span-columns(9); }
    h2.page-title { margin-top: 0; }

	@include media($portrait) {
		.sidebar {
			@include span-columns(4);
            display: block;
			margin-bottom: $gutter;
			padding-bottom: $gutter;
			border-bottom: 1px solid $color-dark;

		}
		.inner-content { @include span-columns(4); }
	}
}

.sidebar {
	h4 {
        margin-top: .8em;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
		border-bottom: 1px solid $color_grey_light;
	}
	.sidebar-navigation {
		@include clear-list;
		margin-bottom: 2em;
		font-size: 1.1em;

        li a {
			padding: 0.4em 0;
			color: $color-brown;
			display: block;

            > i {
                margin-right: .5em;
            }

			&:hover {
				background: #eee;
			}
		}
    }

    .sub-action {
		@include alert-box;
        font-size: 0.85rem;

        i {
            margin-top: 5px;
            margin-right: 10px;
        }
    }
}

.product-grid {
    --product-gutter-width: #{$fg-gutter};
	display: flex;
    flex-wrap: wrap;
    margin-right: calc(-0.5 * var(--product-gutter-width));
    margin-left: calc(-0.5 * var(--product-gutter-width));

	.product {
        width: 25%;
        flex: 0 0 auto;
        max-width: 100%;

        padding-right: calc(var(--product-gutter-width) * 0.5);
        padding-left: calc(var(--product-gutter-width) * 0.5);
        margin-bottom: var(--product-gutter-width);

        .product-teaser-inner {
            border: 1px solid $color_grey_light;
            border-radius: 3px;
            overflow: hidden;
            transition: border-color linear 150ms;
            height: 100%;

            &:hover {
                border-color: darken($color_dark, 50%);
            }
        }

		// @include span-columns(3 of 12);
		// @include omega(4n);

		@include media($mobile) {
            width: 50%;
		}

        @include media($mobile-small) {
            width: 100%;
        }

		.info {
			padding: 10px;
		}

		.price,
		h3 a { transition: color linear 150ms; }


		.price {
			padding: 5px 0 0 0;
			font-weight: bold;
			font-size: 1.1em;

			.unit {
				font-size: 0.6em;
			}

			.orig {
				color: $color-text;
				display: block;
				text-decoration: line-through;
				font-size: 0.8em;
			}

            .price-line-alt {
                color: $color_grey_dark;
                font-size: 12px;
                margin-top: -3px;
            }
		}

		&.promo {
			.price {
				color: $color-promo;
			}
			border-color: $color-promo;
		}

		h3 {
			a { color: #000; }
			font-weight: normal;
			min-height: 40px;
			padding: 0;
			margin: 0;
			font-size: 1em;
		}

		.image {
			img {
				width: 100%;
			}
		}

		&.dummy {
			//border: 1px dashed $color-orange;
			transition: border-color 150ms ease-in;
			a,
			.price {
				color: $color-orange;
			}
			&:hover {
				border-color: darken($color-orange, 40%);
				a { color: $color-orange; }
			}
		}
	}
}


table.data-table,
table.order-table,
table.pivot,
table.products {
	width: 100%;
	margin-bottom: 2em;
	border-collapse: collapse;

	th,
	td {
		padding: 0.8em;

		&.align-right {
			text-align: right;
		}
	}

	tr.processed {
		td {
			background-color: rgba($color_dark, 0.2);
			font-weight: bold;
		}
	}

	// tr.expired td {
	// 	opacity: 0.5;
	// 	font-style: italic;
	// }

	tr.promo {
		.price {
			color: $color-promo;
			.orig {
				color: $color-text;
				display: block;
				font-size: 0.8em;
				text-decoration: line-through;
			}
		}
	}

	thead {
		text-align: left;
		background: $color-orange;
		th {
			color: white;
			border-bottom: 3px solid darken($color-orange, 10%);
			a { color: white; }
		}
	}

	&.alt thead {
		background-color: $color_grey_light;

		th {
			color: black;
			border-bottom-color: darken($color_grey_light, 15%);
		}
	}

	tbody {
		tr td {
			border-bottom: 1px solid $color_grey_light;
			a { color: black }
		}
		tr:nth-child(odd) td {
			background: lighten(#eee, 2%);
		}

		tr:hover td {
			background-color: darken(#eeeeee, 2%);
		}

		tr.for-order {
			td {
				//border: 1px dashed $color-orange;
			}
		}

		div.for-order {
			font-size: 0.9em;
			color: $color-orange;
		}

		tr.is-special td {
			color: lighten(#000, 50%);
		}
	}

	tfoot {
        tr td {
            border-bottom: 1px solid $color_grey_light;
        }

        tr:last-child td {
            border-bottom: none;
        }

		td {
			font-weight: bold;
            font-size: 1.1em;
		}

        td.label-light,
		td.label {
			text-align: right;
        }

        .label-light {
            font-weight: normal;
        }
    }

    .personalization-label {
        font-size: .9em;
        color: $color_grey_dark;
    }

    .list-actions {
        .actions {
            display: flex;
            align-items: center;
            justify-content: right;
        }

        .list-button {
            span { display: none; }

            border-radius: 5px;
            background-color: $color_orange;
            padding: .2em .5em;
            margin-left: 5px;
            transform: background-color 150ms ease-in-out;

            &:before {
                color: white;
            }

            &.icon-heart:before {
                @include font-awesome($fa-var-heart);
            }
            &.icon-cart:before {
                @include font-awesome($fa-var-cart-plus);
            }
            &.icon-view:before {
                @include font-awesome($fa-var-eye);
            }

            &:hover {
                background-color: darken($color_orange, 10%);
            }
        }
    }

	td.action {
        text-align: right;
        opacity: 1;

        .action-link {
            display: inline-block;
        }

		a {
			background-color: $color_orange;
			margin: 0 0.1em;
			color: #fff;
			border-radius: 0.2em;
			padding: 0.3em 0.5em;
            font-size: 0.9em;

            &.alt {
                background-color: red;
            }
		}

		@include media($mobile) {
			.view,
			span { display: none; }
			.button:before {
				margin-right: 0;
			}
		}
    }

    .selection {
        border: 1px solid #eee;
        padding: .3em .5em;
        background: white;
    }

	.price {
		text-align: center !important;
		font-weight: bold;
	}

	@include media($mobile) {
		font-size: 0.9em;
	}
}

table.pivot {
    tbody {
        td {
            text-align: center;

            &.label {
                text-align: left;
            }
        }
    }

    .fa-times {
        color: #cccccc;
    }
    .fa-check {
        color: green;
    }
}

.breadcrumb {
	margin: 0;
	padding: 20px 0 10px 0;
	li {
		display: inline-block;
		padding: 0 0 0 7px;
        color: #666;

        &:after {
            margin-left: 8px;
            @include font-awesome($fa-var-caret-right);
        }

        &:last-child:after {
            display: none;
        }

		a {
			color: #000;
			&:hover { text-decoration: underline; }
		}

		&:first-child { padding-left: 0; }
		&:last-child { padding-right: 0; background: none; }

        @include media($portrait) {

        }
	}
}

.pagination {
	@include clear-list;
	@include clearfix;

	text-align: center;
	padding: $gutter 0;

	li {
		display: inline;
		&.active a { background-color: $color-orange; }
		a {
			display: inline-block;
			margin-bottom: 0.8em;
			background-color: $color-dark;
			padding: 6px 10px;
			color: #fff;
			margin-right: 5px;
			transition: opacity linear 150ms;
			&:hover {
				opacity: 0.9;
			}
		}
	}
}

.wall {
	margin: 0 -10px;
	.item {
		.inner {
			border: 1px solid darken($color_grey_light, 10%);
			overflow: hidden;
			border-radius: 5px;
		}

		.image {
			overflow: hidden;
			img { transition: transform ease-out 150ms; }
			img:hover { transform: scale(1.1); }
		}

		.teaser {
			padding: 10px;
		}

		padding: 10px;
		width: 25%;
		@include media($mobile) {
			width: 50%;
		}
	}
}

.post-info {
	border: 1px solid $color_grey_light;
	padding: 5px 0;
	margin-bottom: $gutter;
	border-left: none;
	border-right: none;
	a { color: $color-dark; text-decoration: underline; }
	span { padding-right: 20px; }
	i { margin-right: 5px; }
}

.node-content {
	@include row;
	&.has-gallery {
		.body {
			@include span-columns(6);
			p:first-child { margin-top: 0; }
		}
	}

	.body {
		img { display: inline-block; }
	}

	#media-gallery {
		@include span-columns(6);
	}

	@include media($mobile) {
		#media-gallery {
			@include span-columns(4);
		}
		&.has-gallery .body {
			@include span-columns(4);
		}
	}
}

#media-gallery {
	.large {
		margin-bottom: $gutter;
		img {
			width: 100%;
		}
	}
	.thumbs {
		@include row;
		.image {
			margin-bottom: $gutter;
			@include span-columns(3);
			@include omega(4n);
		}
	}
}

#infscr-loading {
	clear: both;
	margin: 0 auto;
	text-align: center;
}

.jobs {
	.job {
		@include row;
		border-bottom: 1px solid $color-orange;
		padding: 1em 0;
		.image {
			img { width: 100%; }
			@include span-columns(2);
		}
		.info {
			@include span-columns(10);
		}

		@include media($mobile) {
			.image { @include span-columns(2); }
			.info { @include span-columns(2); }
		}

		@include media($mobile-small) {
			.image { @include span-columns(4); }
			.info { @include span-columns(4); }
		}

		&:last-child {
			border-bottom: none;
		}

		.actions {
			padding-top: 1em;
			.button {
				margin-top: 1em;
			}
		}
	}
}


.product-detail {
    @include row;

	margin: 1em 0;

	h2 {
		padding-top: 0;
		margin-top: 0;
	}

	.title {
        h1,
		h2 {
			padding: 0 0 0.5em 0;
			margin: 0;
		}
		margin-bottom: 1.5em;
	}

	.media {
        .thumbs {
            @include clear-list;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 10px -5px;

            > li {
                overflow: hidden;
                border: 1px solid #ccc;
                overflow: hidden;
                border-radius: 10px;

                margin-bottom: 10px;
                width: calc((100% / 4) - 10px);
                margin: 0 5px;
                cursor: pointer;

                &.obj-thumb {
                    background-color: $color_grey_light;
                    height: 100%;
                }
            }
        }

        .media-item {
            margin-bottom: 1em;
            background-color: #eee;
            border: 1px solid #ccc;
            overflow: hidden;
            border-radius: 10px;

            transition: opacity ease-in-out .5s;

            &.loading {
                opacity: .5;
            }

            .has-model { display: none; }
        }

        .model-viewer-wrapper {
            position: relative;

            &.not-supported {
                padding: 3rem;
                text-align: center;
            }

            .rotate-icon {
                width: 40px;

                .cls-1 {
                    fill: #ccc !important;
                }

                position: absolute;
                top: 25px;
                right: 25px;
            }
        }
	}

	.media {
		@include span-columns(5);
	}

	.info {
        @include span-columns(7);
	}

	@include media($mobile) {
		.media { @include span-columns(4); }
		.info {	@include span-columns(4); }
	}

	.highlight {
		background-color: #eee;
		padding: 1em;
		color: $color-brown;
		@include row;

		.length,
		.price {
			@include span-columns(6);
			font-size: 2em;
			.unit {
				font-size: 0.6em;
			}
		}

		.price-info {
			@include span-columns(6);
			text-align: right;
		}

		@include media($mobile) {

		}
	}

	h3 {
		margin: 2em 0 1em 0;
		text-transform: uppercase;

		&.lengths:before {
			margin-right: .5em;
			@include font-awesome("\f07e");
		}
	}

    .vat-warning {
        margin-bottom: 2em;
    }

	.limited-stock {
		color: $color-orange;
		&:before {
			margin-right: .5em;
			@include font-awesome("");
		}
	}

	.highlight-info {
		margin: $gutter 0;
		padding: 0.8em;
		background: $color_grey_light;
		&:before {
			margin-right: .5em;
			@include font-awesome("");
		}
	}

	.attributes {
		@include clearfix;
		dt {
			font-weight: bold;
			float: left;
			min-width: 35%;
		}
		dd {
			padding-bottom: 0.2em;
			@include clearfix;
		}

		@include media($mobile) {
			dt:after {
				content : ":  ";
				margin-right: 5px;
			}
		}
	}

	.documents {
		@include clear-list;
		float: left;
		.pdf:before {
			@include font-awesome($fa-var-file-pdf);
			margin-right: 0.5em;
		}
	}

	.attributes-wrapper {
		margin: 1.5em 0;
		border: 1px solid $color_grey_light;
		border-left: none;
		border-right: none;
	}

	.volume-discounts {
		margin-top: 1.5em;
		padding: 1em;
		border: 2px solid $color_grey_light;
        border-radius: 5px;
		display: block;
		min-width: 300px;
		h4 {
			margin-top: 0;
		}
	}

	.actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include media($mobile) {
            form {
                margin: 10px 0;
            }
        }

        button,
		a {
			margin-right: 1em;
		}

		.add-continue {
			text-decoration: underline;
		}
		.add.wishlist {
			&:before {
				@include font-awesome($fa-var-heart);
				margin-right: 10px;
			}
		}
	}

	.disclaimer {
		font-style: italic;
		color: #999;
	}

	.description {
		padding: 1.5em 0;
	}

	.list-info {
		@include clearfix;
		@include clear-list;
		li {
			float: left;
			padding: 1em;
			background-color: $color_grey_light;
			margin-right: .3em;
		}
	}

	.block-region-product {
		ul {
			@include clear-list;
			li a { color: black; }
			li:before {
				@include font-awesome("");
				color: $color-orange;
				margin-right: 0.5em;
			}
		}
	}
}

.lists-actions {
	display: flex;
    justify-content: space-between;
    align-items: center;

    .secundary {
        margin-left: auto;
    }

	.secundary a {
		margin-left: 1em;
		font-size: 1.1em;
		display: inline-block;
		&:before {
			margin-right: 0.5em;
		}
		&.print:before {
			@include font-awesome($fa-var-print);
		}
		&.pdf:before {
			@include font-awesome($fa-var-file-pdf);
		}
	}

	@include media($mobile) {
		padding-bottom: $gutter;
		.secundary {
			text-align: left;
			font-size: 0.8em;
		}
	}
}

.lists-sidebar {
    margin-bottom: 1rem;

	.total {
		text-align: center;
		background-color: $color-dark;
		color: white;
		padding: 1em 0;
		.price { font-size: 3em; }

		@include media($mobile) {
			padding: 0.5em;
			.price { font-size: 1em; }
        }

        .price-inc {
            font-style: italic;
            color: #ccc;
        }
	}
	.cartinfo {
        padding: 1em 0;

        &:first-child { margin-top: 0; }
		&:last-child { border-bottom: none; }
		p:first-of-type {
			padding: 0;
			margin: 0;
		}

		.action { display: inline-block; padding-top: 1em; }
	}

	.actions {
		@include clear-list;
		margin-top: 2em;
		padding: 1em;

		li > a {
			font-size: 1.1em;
			color: $color-brown;
			display: block;
			margin-bottom: 0.4em;
			&:before {
				margin-right: 0.5em;
				color: black;
				font-size: 1.4em;
				vertical-align: middle;
			}
			&.download:before {
				@include font-awesome("");
			}
			&.print:before {
				@include font-awesome("");
			}
			&.mail:before {
				@include font-awesome("");
			}
		}
	}
}

.popup-form {
	min-width: 700px;
	@include media($mobile) {
		min-width: auto;
	}
}


.delivery-form {
	@include row();

	padding: 2em;
	margin-bottom: 2em;

	background-color: lighten($color_grey_light, 7%);
	color: lighten($color_dark, 40%);

	> h3 {
		margin-top: 0;
		margin-bottom: 0.75em;
		font-size: 1.5em;
		text-transform: uppercase;
	}

	.input {
		@include span-columns(3);

		padding: 0;

		input, select {
			background-color: lighten($color-dark, 70%);
			color: lighten($color_dark, 40%);

			&:focus {
				background-color: lighten($color-dark, 70%);
			}
		}
	}

	.input:nth-child(3) {
		@include span-columns(7);
	}

	.submit {
		@include span-columns(2);

		input {
			display: block;
			width: 100%;
		}
	}

	@include media($mobile) {
		.input, .input:nth-child(3), .submit {
			@include span-columns(4);
			margin-bottom: 10px;
		}
	}
}

.delivery-cost, .delivery-franco {
	background-color: lighten($color_grey_light, 7%);
	padding: 1em;
	margin-bottom: 1em;
	border-bottom: 1px solid $color-orange;
	position: relative;
	font-size: 1.25em;

	> span {
		position: absolute;
		right: 0;
		top: 0;
		padding-top: 10px;
		padding-bottom: 10px;

		height: 100%;
		width: 20%;

		text-align: center;

		background-color: $color-orange;
		color: white;
		font-weight: bold;
		font-size: 1.50em;
	}

	@include media($mobile) {
		> span {
			margin-top: 10px;
			position: static;
			display: block;
			width: 100%;

		}
	}
}


.modal-add {
	max-width: 700px;
}

.professionals-auth {
	.col {
		@include span-columns(6);
	}

	.actions {
		padding-top: 1em;
		text-align: right;
		a {
			display: inline-block;
			margin-right: 1em;
		}
	}

	h3 {
		&:before {
			margin-right: 0.7em;
			font-size: 1.4em;
			vertical-align: middle;
			color: darken($color_brown, 10%);
		}
		&.login:before {
			@include font-awesome($fa-var-desktop);
		}
		&.create:before {
			@include font-awesome($fa-var-user);
		}
	}

	@include media($mobile) {
		.col {
			@include span-columns(4);
			margin-bottom: 2em;
		}
	}
}

.small-info-well {
	color: lighten($color_text, 50%);

	&.expanded {
		color: $color-text;
		background: $color_grey_light_2;
		padding: 1.5em;
	}
}

.large-info-well {
	margin-bottom: 2em;
    font-size: 0.8rem;

	h2 { margin: 0; padding: 0; }

	dl {
		margin: 0;
		padding: 0;
		dt {
			float: left;
			font-weight: bold;
			min-width: 140px;
		}
		dd {
			padding: 0.2em 0;
		}
	}
}

.pricelist-categories {
	@include clear-list;
	margin-top: 2em;
	ul {
		margin: 0 0 0 30px;
	}
	li {
		padding: 0.2em 0;
	}
}

#categories-tree {
	margin: 1em 0 2em 0;
}

.dashboard-box {
    margin-bottom: 3em;

    .info-small {
        color: $color-brown;
    }
}

.popup {
	h2 {
		text-transform: uppercase;
		color: $color-orange;
		margin: 0 0 1em 0;
        padding: 0;

        strong {
            color: $color-dark;
        }
	}
	.scrollable-list {
		background: #eee;
		max-height: 250px;
		min-width: 400px;
		overflow-y: scroll;
		border: 1px solid #eee;
        padding: 1em;

        list-style-type: none;
	}
	.action {
		padding-top: 0.5em;
		text-align: right;

		.button { margin-left: 1em; }
	}
	padding-bottom: 0.5em;
}


.option-dummy {
    color: $color-orange;
    font-weight: bold;
    margin: 0 0 1.5rem 0;

    &:before {
        @include font-awesome("");
        margin-right: 0.5em;
    }
}


.icon-alert {
    margin-bottom: 5px;

    i.fa {
        margin-right: 5px;
    }
    &.icon-alert-highlight {
        color: $color-orange;
    }
}

.indicative-price-container {
    margin-bottom: $gutter;
    border-radius: 5px;
    border: 5px solid #eee;
    display: inline-block;
    padding: 10px;

    .price-label {
        font-weight: bold;
        display: block;
        color: $color_grey_dark;
        font-size: 12px;
    }

    .price {
        font-weight: bold;
        font-size: 18px;
    }
}

.product-price-info {
	margin-bottom: 1.5em;
	color: black;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    //height: 360px;

    @include media($tablet) {
        height: 100%;
    }

    &.includes-simplified {
        display: block;

        .option-group
        {
            width: 60%;
        }
    }

	//.option-dummy,
	.option-group {
        width: 48%;

        @include media($tablet) {
            width: 100%;
        }

		margin-bottom: 0.8em;
        display: flex;
        align-items: center;

        .unit-info-wrapper,
		.price {
            height: 100%;
            width: 50%;
        }
	}

	.unit-info-wrapper {
        border-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
		padding: 0.4em 0.7em;
		background: #eee;
		font-size: 0.9em;
        display: flex;
        align-items: center;

		.unit-info.unit-s {
            padding-top: 0.3em;
        }

        .unit-info-wrapper-inner {
            width: 100%;
        }
	}

	.price {
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
		border: 2px solid #eee;
		display: inline-block;
		font-size: 1.2em;
		font-weight: bold;
		padding: 0.55em;
		text-align: center;
        display: flex;
        align-items: center;

        .price-inner {
            text-align: center;
            width: 100%;
        }

		.unit {
			font-size: 0.8em;
		}

		.orig {
			text-decoration: line-through;
			color: black;
		}

		.member-price {
			display: inline-block;
			font-style: italic;
			padding-left: 10px;
			font-size: 0.7em;
			font-weight: normal;
			color: $color-promo;
		}
	}

	&.promo .price {
		color: $color-promo;
	}
}

#lists-table-wrapper {
	//transition: opacity 10ms ease-in-out;

	&.loading {
		//opacity: 0.5;
	}
}

.unit-table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 2em;

	td {
		text-align: center;
		padding: 0.8em;
		border: 1px solid #ccc;

        .options-title {
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: .5em;
        }

        &.sub-options {
            text-align: left;
            background-color: white;
        }

		&.total {
			width: 20%;
			font-size: 1.5em;
			font-weight: bold;
		}

        .inner-form {
            background-color: $color_grey_light_2;
            padding: 15px;
            padding-bottom: 0;
            margin-top: 15px;
            border-radius: 10px;
            position: relative;

            &::before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid $color_grey_light_2;
                position: absolute;
                top: -8px;
                left: 30px;
            }
        }

		&.qty {
			border-right: none;
			width: 25%;
			.label {
				font-weight: bold;
				padding-top: 0.2em;
			}
			.unit {
				width: 45%;
				text-align: center;
				display: inline-block;
				vertical-align: 3px;
			}
			input[type=number] {

				&::-webkit-inner-spin-button {
					opacity: 1;
				}

				width: 50%;
				font-size: 1.5em;
				text-align: center;
				border: 1px solid #ccc;
				background: white;
				font-weight: bold;
				border-radius: 5px;
			}
		}
		&.action {
			width: 60px;
			.delete {
				font-size: 1.7em;
				color: black;
				&:before { @include font-awesome($fa-var-trash); }
			}
        }

        .unit-label {
            font-size: 0.8em;
            color: #666;
        }
	}
	tr:not(.item-row):nth-child(even) {
		td { background-color: $color_grey_light_2; }
	}

    tr.even {
		td { background-color: $color_grey_light_2; }
	}

	@include media($mobile) {
		font-size: 0.7em;
		.unit-label-desc { display: none; }
		.unit-rate { display: none; }
        td.qty {
            width: auto;
            max-width: 130px;
        }
	}
}

.blog-items {
	$sizer: 14px;
	@include row;
	margin: 0 (-$sizer) $sizer (-$sizer);
    opacity: 0;
    transition: opacity 150ms ease-in-out;

    &.masonry-loaded {
        opacity: 1;
    }

	.blog-teaser,
	.blog-teaser-sizer {
		width: 25%;
		padding: $sizer;
	}

	.blog-teaser {
		&.highlight {
			width: 50%;
		}

		.inner {
			box-shadow: 0px 0px 4px 0 rgba(0,0,0,0.3);
			border-radius: 6px;
			overflow: hidden;
		}


		@include media($mobile) {
			width: 50%;
			&.highlight .inner {
				background-color: #eee;
			}
		}

		@include media($mobile-small) {
			width: 100%;
		}

		.image {
			overflow: hidden;
		}

		img {
			width: 100%;
			transition: transform 150ms ease-in;
			&:hover {
				transform: scale(1.1);
			}
		}

		.info {
			margin: 0;
			padding: 1em;
		}

		h3 {
			font-size: 1em;
			margin: 0 auto;
		}

		p {
			font-size: 0.9em;
			color: #666;
		}

		.action {
			@include clearfix;
			padding-top: 1em;

			.share-btn {
				float: left;
				margin-top: 0.7em;
			}

			.more {
				font-weight: bold;
				text-transform: uppercase;
				display: inline-block;
				float: right;
				border: 2px solid $color-orange;
				padding: 0.4em;
				margin: 0 auto;
				text-align: right;
				background-color: white;
				transition: background 200ms linear;
				&:after {
					@include font-awesome("");
					margin-left: 10px;
				}
				&:hover {
					background: darken($color-orange, 5%);
					border-color: darken($color-orange, 5%);
					color: white;
				}
			}
		}

		.tags {
			@include clear-list;
			border: 1px solid #eee;
			border-left: none;
			border-right: none;
			padding: 0.5em 0;
			&:before {
				@include font-awesome("");
				margin-right: 0.5em;
			}
			li {
				display: inline-block;
				padding: 0 0.1em;
				a {
					display: inline-block;
					padding: 0.1em 0.3em;;
				}
			}
		}
	}
}

.node-search-results {
    margin-bottom: $gutter;
    padding-bottom: $gutter;

    .pages-search & {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .actions {
        text-align: right;
    }
}

.node-search-results-items {

    &.node-search-results-items-multiple {
        @include flex-row();

        article {
            @include flex-columns-ready;
            @include flex-columns(2);

            @include media($mobile) {
                @include flex-columns(1);
            }
        }
    }

    article {
        display: flex;
        align-items: center;
        padding: $flex-gutter-width;
        box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.3);
        margin-bottom: $flex-gutter-width;

        .fa {
            margin-top: 4px;
        }

        .content {
            padding-left: 10px;
        }

        h3 {
            margin: 0;
            padding: 0;
        }

        .category {
            color: $color_grey_dark;
            font-size: 0.85em;
        }

        p {
            margin-bottom: 0;
            font-size: .85em;
        }
    }
}

.blog-search {
	margin-bottom: 1em;
	.input {
		position: relative;
		input {
			font-size: 1.3em;
			padding: 1em !important;
			border-radius: 0.5em;
		}
		&:after {
			@include font-awesome("\f002");
			position: absolute;
			font-size: 1.5em;
			right: 1em;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	@include media($mobile) {
		margin-bottom: 0;
	}
}

.empty {
	color: #999;
	font-size: 2em;
	text-align: center;
}

.empty-small
{
    color: #999;
    font-style: italic;
}


.tag-filter-list {
	margin: 1em 0;

	ul.list {
		@include clearfix;
		@include clear-list;
		text-align: center;
		li {
			display: inline;
			a {
				display: inline-block;
				text-transform: uppercase;
				border: 1px solid $color_grey_light;
				color: black;
				margin: 0.5em;
				padding: 0.6em 1em;
				&.active {
					background-color: $color-orange;
					color: white;
				}
				&.all {
					text-transform: uppercase;
					margin-right: 0.5em;
				}
			}
		}
	}

	@include media($mobile) {
		margin: 0;
		ul.list li a {
			//@include span-columns(2);
		}
	}
}

.fancybox-wrap {
	.recover-form {
		max-width: 650px;
	}

    .page-title {
        padding-top: 0;
        margin-top: 0;
    }
}

.related {
	padding-top: 1em;
}

.faq {

    .faq-anchor {
        position: relative;
        top: -80px;
        visibility: hidden;
    }

	.faq-item-categories {
        @include clear-list;
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        margin-top: $gutter  * 2;

        > li {
            margin-bottom: $gutter;

            a {
                padding: $gutter $gutter/2;
                display: block;
                color: black;
                text-transform: uppercase;
                font-weight: bold;
                border: 1px solid $color_grey_light;
                border-radius: 5px;
                transition: background-color 150ms ease-in-out;
                font-size: 1.1rem;

                &:hover {
                    background-color: #e0e0e0;
                }
            }

            width: 32%;
            text-align: center;
        }
	}

    .faq-category-group {
		border: 1px solid $color_grey_light;
        border-radius: 5px;
        margin-bottom: $gutter;

        h2 {
			background: #eee;
            margin: 0;
            padding: $gutter / 2;
        }

        .items {
            padding: $gutter;

            .faq-questions {
                @include clear-list;
                margin-bottom: $gutter*2;

                li a {
                    display: block;
                    padding: 1em;
                    font-weight: bold;
                }
                li:nth-child(odd) a {
                    background: #eee;
                }
            }
        }

        .faq-item {
            h3 {
                color: $color_orange;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
            }

            .answer {
                border-left: 4px solid #eee;
                padding-left: $gutter;

                ul {
                    padding-left: $gutter;
                }
            }
        }
    }
}

.bulk-rates {
	@include clear-list;
}

.groups-grid {
	h3 {
		min-height: 35px;
	}
	.button { margin-top: 0.5em; }
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    .title-wrapper {
        max-width: 50%;
        margin: 0 20px 0 0;

        h1 {
            @include text-truncate;
            margin: 0;
            display: block;
        }
    }

    @include media($tablet) {
        flex-wrap: wrap;
        justify-content: space-between;

        .title-wrapper {
            width: 100%;
            max-width: 100%;
            margin-bottom: .5rem;
        }

        .header-item {
            margin: .5rem 0;
        }
    }

    .header-item {
        &:first-of-type {
            margin-left: auto;

            @include media($tablet) {
                margin-left: 0;
            }
        }

        .label {
            display: inline-block;
            margin-right: 1rem;
        }

        .label.count {
            border-right: 1px solid $color_grey_light;
            padding-right: 1rem;
        }

        @include media($mobile) {
            .main-label {
                display: none;
            }
        }
    }
}

.viewmode {
    margin-right: 1rem;

	.tile,
	.list {
		color: black;
		padding: 0.4em;

		&:before {
			margin-right: 0.5em;
		}

		&.active {
			color: $color-orange;
			border: 1px solid $color-orange;
		}
	}
	.list:before {
		@include font-awesome("");
	}
	.tile:before {
		@include font-awesome("");
	}
}

.inline-dropdown-menu {
    @include clear-list;
    display: inline-block;
    border: 1px solid $color_grey_light;
    border-radius: 5px;
    position: relative;
    min-width: 180px;

    &:hover {
        border-color: #999;

        li > ul {
            border-color: #999;
        }
    }

    > li {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 15px;

        &:after {
            @include font-awesome($fa-var-chevron-down);
        }
    }

    li > ul {
        @include clear-list;
        display: none;
        position: absolute;
        top: calc(100% - 3px);
        left: -1px;
        right: -1px;
        background-color: white;
        border: 1px solid $color_grey_light;
        border-top: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 0 5px 5px rgba(0,0,0,0.1);
    }

    li li {
        padding: 3px 15px;
    }

    .open {
        .options {
            display: block;
        }

        &:after {
            @include font-awesome($fa-var-chevron-up);
        }
    }
}

.transport-lookup-form {
	padding: 1em;
	background: $color_grey_light;
	margin-bottom: $gutter;

	input,
	select {
		vertical-align: middle;
		margin-right: 0.3em;
	}

	input[type=text] {
		background: lighten($color_grey_light, 10%);
		border: 0;
		padding: 0.30em;
		&:focus {
			outline: 0;
			background: white;
		}
	}

	input[type=submit] {
		padding: 0;
		font-size: 0.9em;
		padding: 0.35em 1em;
		vertical-align: middle;
	}

	@include media($mobile) {
		input[type=text] { width: 38%; }
	}
}

.transport-lookup-result {
    margin-bottom: $gutter;
    border: 1px solid $color_grey_light;
    padding: 1em;

    h3 {
        margin: 1.5rem 0;

        &:first-child {
            margin-top: 0;
        }
    }

    table {
        width: 100%;

        .type {
            width: 50%;
            font-weight: bold;
        }
    }

    @include media($mobile) {
        table {
            .type { width: 50%; }
        }
    }
}

.transport-dates-row {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row column;
    border-bottom: 1px solid #eee;
    padding: 5px 0;

    &:last-child {
        border-bottom: none;
    }

    .date {
        padding: 5px 10px;
        margin-left: 10px;
        margin-bottom: 5px;
        margin-top: 5px;
        background-color: #eee;
        border-radius: 5px;
        flex: 0 1 2;
    }

    .month {
        font-weight: bold;
        width: 20%;
        padding-left: 0;
    }

    .dates {
        width: 80%;
        display: flex;
        flex-wrap: wrap;
    }
}

.member-heading{
    &.with-action {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

	h2 {
		margin: 0; padding: 0;

		small {
			font-size: 0.7em;
			float: right;
			color: $color_grey_light;
			padding-left: 1em;
		}
	}
	padding-bottom: 1em;
	margin-bottom: 2em;
	border-bottom: 1px solid $color_grey_light;

	.customer-id {
		margin-bottom: 0.3em;
		padding: 0.5em 0;
		.id {
			border-radius: 0.2em;
			border: 1px solid $color-text;
			font-weight: bold;
			padding: 0.1em 0.2em;
		}
	}
}

.masters-toggles {
	@include clear-list;
	@include clearfix;
	li {
		width: 32%;
		margin-right: 2%;
		float: left;
		margin-bottom: 1em;

        @include media($mobile) {
            width: 100%;
            margin-right: 0;
        }

		&:nth-child(3n) {
			margin-right: 0;
		}

		> .inner,
		> a {
			@extend .member-icon;
			color: $color-text;
			position: relative;
			//min-height: 90px;
			&:before {
				font-size: 2em;
				position: absolute;
				left: 15px;
				top: 50%;
				transform: translateY(-50%);
			}
			border-radius: 4px;
			padding: 1em 1em 1em 4em;
			border: 1px solid $color_grey_light;
			display: block;
			h4 { margin: 0; padding: 0; }
			transition: background-color ease-in 150ms;
		}

		> a:hover {
			background: lighten($color_grey_light, 20%);
		}

		&.not-approved > .inner,
		&.not-approved > a {
			opacity: 0.3;
			border-color: #999;
		}

		&.active-master a {
			color: $color-orange;
			border-color: $color-orange;
		}
	}
}

.checkout-info {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    margin: 20px -10px;

	.checkout-item {
        flex: 1;
        margin: 0 10px;
        overflow: hidden;
        background-color: lighten(#eee, 2%);
        border-radius: 10px;

        h3 {
            background-color: $color_grey_light;
            margin: 0;
            padding: 1em 2em;
        }

        .inner {
            padding: 1em 2em;
        }
    }

    @include media($mobile) {
        display: block;

        .checkout-item {
            width: 100%;
            margin-bottom: 1em;
        }
    }

	.radio {
		label {
			display: block;
			font-weight: normal;
			//border: 1px solid #ccc;
			margin-bottom: 5px;
		}
		input {
			margin-right: 10px;
		}
	}
}

.order-address-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 20px;

   .manage,
   .address-item {
        border: 1px solid $color_grey_light;
        border-radius: 5px;
        padding: 10px;
        margin: 5px;
        min-width: 22%;

        h4 {
            margin-top: 0;
            padding: 0;
        }

        .actions {
            margin-top: 10px;
        }

        &.active {
            background-color: $color_grey_light;
        }
    }

    .manage {
        border-color: darken($color_grey_light, 20%);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;

        > a {
            display: block;
        }
    }
}

.address-info,
.checkout-item {
    dl > dt {
        font-weight: bold;
        text-transform: uppercase;
    }

    dd {
        margin: 0;
        margin-bottom: 10px;
    }
}

.address-info {
    h3 {
        margin-top: 0;
	}

	dl {
        border: 4px solid $color_grey_light;
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-radius: 5px;

		dt {
            flex-basis: 20%;
            margin: 0;
		}

		dd {
            flex-basis: 80%;
			padding: 0;
            margin: 0;
		}
	}
}

.address-info {
	margin-bottom: 2rem;
}

.accounts-list {
	.master {
		padding: 1em;
		border: 1px solid $color_grey_light;
		margin-bottom: 1em;
		border-radius: 4px;

		> .name {
			text-transform: uppercase;
			font-weight: bold;
			margin-bottom: 0.5em;
			@extend .member-icon;
			&:before { margin-right: 0.5em; }
		}
		.phone,
		.type,
		.address {
			margin-top: 0.5em;
		}

		.status-message {
			border-top: 1px solid $color_grey_light;
			padding-top: 0.5em;
			margin-top: 1em;
			font-weight: bold;
			&:before {
				@include font-awesome("");
			}
		}

		&.not-approved .status-message {
			color: $color_orange;
			&:before {
				@include font-awesome("");
			}
		}
	}
}

#error-page {
	padding: 5em 5em 4em 5em;
	min-height: 250px;
	width: 100%;
	text-align: center;
	img {
		margin: 0 auto 4em auto;
		max-width: 500px;
	}

	@include media($mobile) {
		img { display: none; }
	}
}


.detached-error {
    padding: .8em;
    background: red;
    color: white;
    > a { text-decoration: underline; color: lighten(red, 40%); }
}

#resultWrapper {
    ul {
        @include clear-list;
        margin: 1rem 0;

        > li {
            background: $color_grey_light;
            border: 1px solid $color_grey_light_2;
            background: white;
            margin-bottom: 5px;
            padding: 10px;
            border-radius: 5px;

            &:hover {
                cursor: pointer;
                background-color: $color_grey_light_2;
            }
        }
    }
}

.ui-icon {
    background-image: url("https://code.jquery.com/ui/1.12.1/themes/smoothness/images/ui-icons_222222_256x240.png");
    width: 1rem;
}

.ui-datepicker {
    margin-top: 4px;
    background-color: #fff;
    box-shadow: 0 2px 3px hsla(0,0%,4%,.1),0 0 0 1px hsla(0,0%,4%,.1);
    border-radius: 4px;
    width: auto;
    .ui-datepicker-title {
        text-transform: uppercase;
    }
    td {
        a, span {
            padding: .6em;
            text-align: center;
            border-radius: 4px;
        }
    }
    table {
        font-size: .875rem;
    }
    span.ui-state-default {
        color: $color_grey_light;
    }
    a.ui-state-default {
        cursor: pointer;
        color: $color-text;
    }
    .ui-state-hover {
        background: #eee;
        color: #000;
        top: 2px;
        cursor: pointer;
    }
    .ui-icon-circle-triangle-e { background-position: -48px -192px; }
    .ui-icon-circle-triangle-s { background-position: -64px -192px; }
    .ui-icon-circle-triangle-w { background-position: -80px -192px; }
    .ui-icon-circle-triangle-n { background-position: -96px -192px; }
}

.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.search_suggestions {
    margin: $gutter 0;
    border: 1px solid #eee;
    padding: $gutter;
}

ul.tabs {
    @include clear-list;
    border-bottom: 1px solid $color_grey_light;
    margin: $gutter / 2 0;

    > li {
        display: inline-block;
    }

    > li a {
        margin-right: 5px;
        display: block;
        padding: 5px 20px;
        font-weight: bold;
        border: 1px solid $color_grey_light;
        border-bottom: 0;
        text-transform: uppercase;
        background-color: $color_grey_light;
        color: $color_grey_dark;

        &.active {
            background-color: white;
            border-bottom: 1px solid #fff;
            margin-bottom: -1px;
        }
    }
}

.inline-list {
    display: inline-block;
    margin-bottom: $gutter/2;
    .label {
        font-weight: bold;
    }

    span:not(:first-child) {
        font-style: italic;
    }
}

.col-box {
    border: 1px solid $color_grey_light;
    border-radius: 10px;
    padding: 1em;
    text-align: center;

    .info {
        font-size: 1.5em;
        margin-bottom: 1em;
    }
}
