

[x-cloak] { display: none !important; }

.cake-debug {
    position: relative;
    background: yellow;
    z-index: 50000;
}

h1, h2, h3, h4, h5, h6 {
    line-height: $heading-line-height;
    text-transform: uppercase;
    font-size: modular-scale(1);
}

h1 {
    font-size: modular-scale(4, $base-font-size);

    &.page-title {
        font-size: modular-scale(2, $base-font-size);
    }
}

h2 {
	text-transform: uppercase;
    font-size: modular-scale(2, $base-font-size);
}

h3 {
	text-transform: uppercase;
    font-size: modular-scale(1, $base-font-size);
	color: $color_dark;
}

h4 {
    font-size: modular-scale(1, $base-font-size);
}


img {
	max-width: 100%;
	display: block;

    &.avatar {
        border-radius: 100%;
        max-width: 50px;
        max-height: 50px;
    }
}

a {
	&:link,
	&:visited {
		text-decoration: none;
		color: $color_orange;
	}
}
