.signup-wizard {
	@include clearfix;
	@include clear-list;
	margin: 2em 0;
	li {
		background: #ededed;
		color: white;
		text-align: center;
		padding: 1em 0;
		a { color: white; }
		&.is-current {
			background: $color_orange;
		}

		&.is-complete {
			background: lighten($color_green, 40%);
		}
	}
	&.step-count-6 {
		li {
			@include span-columns(3 of 18);
		}
	}
	&.step-count-5 {
		li {
			@include span-columns(3 of 15);
		}
	}
	&.step-count-4 {
		li {
			@include span-columns(3);
		}
	}
	&.step-count-3 {
		li {
			@include span-columns(4);
		}
	}
	&.step-count-2 {
		li {
			@include span-columns(6);
		}
	}

	@include media($mobile)	{
        margin-bottom: 0;

		li {
			display: none !important;
			&.is-current {
				display: block !important;
				width: 100% !important;
			}
		}
	}
}

.option-list {
	@include clear-list;

	li {
		a {
			background: $color_grey_light;
			padding: 0.5em 1em;
			display: block;
			margin-bottom: 0.5em;
			font-size: 1.2em;
			color: black;
			transition: background linear 150ms;

			@extend .member-icon;
			&:before { margin-right: 0.5em; }

			&:hover {
				background: lighten($color_grey_light, 5%);
			}
		}
	}
}

.inline-option-list {
	@include clear-list;
	text-align: center;
	li {
		display: inline;
		a {
			padding: 0.5em 1em;
			margin: 0.5em;
			color: black;
			background: $color_grey_light;
			display: inline-block;
			font-size: 1.2em;
			transition: background linear 150ms;
			&:hover {
				background: lighten($color_grey_light, 5%);
			}

			&.cancel {
				background: none;
				text-decoration: underline;
				color: red;
			}
		}
	}
}
.inline-option-list-expanded {
	@include clear-list;
	li {
		background: $color_grey_light;
		padding: 0.5em 2em;
		margin-bottom: 1em;
		@include clearfix;
		position: relative;

		&:nth-child(odd) {
			background: $color_grey_light_2;
		}

		.actions {
			.label {
				font-weight: bold;
				margin-bottom: 1em;
			}

			text-align: right;
			position: absolute;
			top: 50%;
			width: 60%;
			right: 2em;
			transform: translateY(-50%);
			form { display: inline; }
		}
		dl {
			width: 50%;
			dt {
				float: left;
				font-weight: bold;
				min-width: 150px;
			}
		}
	}
}

.question-option-main {
	label {
		font-weight: bold !important;
		border: 1px solid $color_grey_light_2;
		border-radius: 5px;
		padding: 1em;
		display: block;
		margin-bottom: 1em !important;
        color: $color_orange;
        input {
            margin-right: 0;
        }
        span {
            width: 85%;
        }
		.info {
			color: $color_text;
			margin: 10px 0 0 0;
			padding: 0;
			font-weight: normal;
		}
	}
}

@-webkit-keyframes blinker {
  from { background: yellow; }
  to { background: $color_grey_light_2; }
}

.question-option {
	display: none;
	position: relative;
	background: $color_grey_light_2;
	padding: 1em;
	border-radius: 5px;
	margin-bottom: 2em;
	&.active {
		animation: blinker 1s;
	}

	.sub-question {
		font-weight: bold;
		&:first-child { padding-top: 0; margin-top: 0; }
	}

	// &:before {
	// 	position: absolute;
	// 	left: -15px;
	// 	transform: translateY(-50%);
	// 	top: 50%;
	// 	content: "";
	// 	display: block;
	// 	width: 0;
	// 	height: 0;
	// 	border-top: 15px solid transparent;
	// 	border-bottom: 15px solid transparent;
	// 	border-right: 15px solid $color_grey_light_2;
	// }

	// &.has-parent:before {
	// 	top: -15px;
	// 	left: 50%;
	// 	transform: translate(-50%, -50%);
	// 	border-top: 15px solid transparent;
	// 	border-right: 15px solid transparent;

	// 	border-left: 15px solid transparent;
	// 	border-right: 15px solid transparent;
	// 	border-bottom: 15px solid $color_grey_light_2;
	// }
}


.col-split {
	@include outer-container;
	padding-top: 1em;
	.col {
		@include span-columns(6);
	}
}

.form-compact {
	.organisation-info {
		border: 1px solid $color_grey_light;
		padding: 1em;
		.name {
			font-weight: bold;
			font-size: 1.5em;
		}
		.vat { color: $color_orange; }
		margin: 1em 0;
	}
}

.existing-master,
.existing-slave {
	padding-top: 2em;
	margin-bottom: 1em;
	.name {
		font-size: 1.2em;
		font-weight: bold;
		margin-bottom: 0.5em;
	}

	.address {
		font-style: italic;
		margin-bottom: 0.5em;
	}

	.actions {
		padding-top: 0.5em;
		a { text-decoration: underline; }
	}

	.email a {
		padding-left: 1em;
		font-style: italic;
		text-decoration: underline;
	}

	.actions,
	.slave-actions {
		.label {
			display: block;
			padding-bottom: 0.5em;

			&.alert {
				padding: 0.5em;
				background: $color_orange;
				color: white;
			}
		}
		form { display: inline; }
		.option {
			margin-top: 1.5em;
			p { font-weight: bold; }
			&:last-child {
				margin-bottom: 0.5em;
			}
		}
	}

	h4 { font-size: 0.8em; }

	p.message {
		&:before { margin-right: 10px; }
		&.approved {
			background: $color_green;
			color: white;
			&:before {
				@include font-awesome("");
			}
		}
		&.not-approved {
			background: $color_orange;
			color: white;
			&:before {
				@include font-awesome("");
			}
		}
		padding: 1em;
	}

	.master {
		padding: 0.5em 1em 1em 1em;
		margin-bottom: 1em;
		background: #eee;
		border: 1px solid darken(#eee, 5%);
		border-left: 5px solid $color_orange;

		.type {
			margin-bottom: 1em;
			text-transform: uppercase;
			@extend .member-icon;
			&:before { margin-right: 0.4em; }
		}
		.approval-status {
			font-size: 0.8em;
			background: $color_orange;
			padding: 0.2em 0.5em;
			display: inline-block;
			vertical-align: 2px;
			margin-left: 1em;
			color: white;
			border-radius: 4px;
			&.approved {
				background: $color_green;
			}
		}
		.vat { font-style: italic; }
	}
}

.existing-master {
	padding: 0.5em 1em 1em 1em;
	margin-bottom: 1em;
	background: #eee;
	border: 1px solid darken(#eee, 5%);
	border-left: 5px solid $color_orange;
}
