@mixin clear-list() {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

@mixin font-awesome($icon) {
    content: fa-content($icon);
    font-family: "Font Awesome 5 Free";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
}

@mixin omega-reset($nth, $context: null) {
	@if $context == null { &:nth-child(#{$nth}) { margin-right: flex-gutter(); } }
	@else { &:nth-child(#{$nth}) { margin-right: flex-gutter($context); } }
	&:nth-child(#{$nth}+1) { clear: none; }
}


.member-icon {
	&.type-p:before { @include font-awesome(''); 	}
	&.type-a:before { @include font-awesome(''); }
	&.type-i:before { @include font-awesome(''); }
	&.type-l:before { @include font-awesome(''); }
	&.type-u:before { @include font-awesome(''); }
	&.new:before { @include font-awesome(""); }
}

@mixin alert-box {
    background: $color_grey_light_2;
    border: 1px solid $color_grey_light;
    border-radius: 5px;
    padding: 1em;
    margin: 0;
}

/** Super duper simple flex grid mixins **/
@mixin flex-row($gutterWidth: $flex-gutter-width) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -($flex-gutter-width/2);
    margin-right: -($flex-gutter-width/2);
}

@mixin flex-columns-ready($gutterWidth: $flex-gutter-width) {
    margin-left: $gutterWidth/2;
    margin-right: $gutterWidth/2;
}

@mixin flex-columns($count: 2, $gutterWidth: $flex-gutter-width) {
    width: calc(100% / #{$count} - #{$gutterWidth});
}

@mixin text-truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
