.form-info {
	@include clearfix;
	margin-bottom: 2em;
	border-left: 5px solid $color_orange;
	padding: 1em;
	box-shadow: 3px 3px 8px rgba(0,0,0,0.15);
	&:before {
		@include font-awesome($fa-var-info-circle);
		margin-right: 15px;
		font-size: 1.2em;
	}
}

.form-info-box {
	// border-left: 5px solid $color_orange;
	// padding: 1em;
	// box-shadow: 3px 3px 8px rgba(0,0,0,0.15);

    .info {
        font-size: .9em;
        color: $color_grey_dark;
    }

    .form-info-box-options {
        margin-top: 1rem;
    }
}

.form-control-help {
    padding: .5em 0;
    display: block;
    color: #999;

    &:before {
		@include font-awesome($fa-var-info-circle);
		margin-right: 5px;
		font-size: 1.2em;
	}
}

.form-info-message {
	background: $color_grey_light_2;
	padding: 1em;
}

form {
	.form-info-legal {
		font-size: 0.9em;
		color: darken($color_grey_light, 25%);
		font-style: italic;
		margin-bottom: 1em;
		&:before {
			margin-right: 0.5em;
			@include font-awesome($fa-var-info-circle);
		}
	}
	.input {
		padding: 5px 0 10px 0;

		label {
			font-weight: bold;
			display: block;
			margin-bottom: 5px;
		}

		select,
		textarea,
        input[type="email"],
        input[type="number"],
        input[type="password"],
        input[type="tel"],
		input[type="text"] {
			display: inline-block;
			width: 100%;
			min-height: 34px;
			line-height: 1.42857143;
			background-color: #eeeeee;
			border: none;
			padding: 8px;
			transition: background-color linear 150ms;
			border-radius: 4px;
			font-size: 1.2em;

			&:focus {
				outline: none;
				background-color: darken(#e9e9e9, 5%);
			}

			&.thirth {
				max-width: 33%;
			}

			&.half {
				max-width: 50%;
			}
        }

        &.date .options {
            display: flex;

            select {
                width: auto;
                margin-right: .5em;
            }
        }

        &.datepicker-wrapper {
            .field {
                max-width: 40%;
                position: relative;
                &:after {
                    position: absolute;
                    top: 48%;
                    transform: translateY(-50%);
                    right: 20px;
                    font-size: 1.1em;
                    @include font-awesome($fa-var-calendar-alt);
                }
            }
        }

		&.checkbox,
		&.radio {
			label {
                display: flex;
                align-items: center;
                font-weight: normal;
                flex-wrap: wrap;

				input {
					padding: 0;
					line-height: 0;
					height: auto;
					margin-top: 2px;
					margin-right: .5em;
					width: auto;
				}

                a {
                    margin-left: .3rem;
                }
			}
		}

		&.checkbox {
			padding: 0;
		}

		&.radio {
			label:not([for]) {
				padding-bottom: 0.5em;
			}
		}

        &.inline {
            display: flex;
            align-items: center;

            label {
                margin: 0;
                margin-right: .5rem;
            }

            select {
                width: auto;
                padding: 4px;
                font-size: 1em;
                min-height: auto;
            }
        }

        &.select .checkbox {
            label {
                font-weight: normal;
            }
            input {
                margin-right: 5px;
            }
        }
	}

	.street {
		@include row;
		.input {
			&:first-child {
				@include span-columns(8);
			}
			&:last-child {
				@include span-columns(4);
			}
		}
	}

	.street-w-box {
		@include row;
		.input {
			&:first-child {
				@include span-columns(7);
			}
			&:nth-child(2) {
				@include span-columns(3);
			}
			&:last-child {
				@include span-columns(2);
			}
		}
	}

	.half {
		@include row;
		.input {
			&:first-child {
				@include span-columns(6);
			}
			&:last-child {
				@include span-columns(6);
			}
		}
	}

	.city {
		@include row;
		.input {
			&:first-child {
				@include span-columns(4);
			}
			&:last-child {
				@include span-columns(8);
			}
		}
	}

	.action {
		button,
		input[type=submit] {
            display: block;
            width: 100%;
            margin-bottom: 0.5em;
        }
	}

	fieldset {
		border: none;
		padding: 0;
		legend {
			font-weight: bold;
			font-size: 1.5em;
			margin: 0;
			padding: 1.5em 0 0.5em 0;
			color: $color_brown;
		}

		&:first-of-type legend { padding-top: 0; }
	}

	.error-message {
		color: red;
		padding-top: 0.5em;
		margin-bottom: 1em;
		&:before {
			@include font-awesome($fa-var-exclamation-circle);
			margin-right: 0.5em;
			float: left;
		}
		ul {

			margin-top: 0;
			padding-top: 0;
		}
	}
}

.form-action-sml,
.form-action {
	padding-top: 2em;
    padding-bottom: 2em;
	text-align: right;

	.prev {
		margin-right: 1em;
		text-decoration: underline;
		&:before {
			@include font-awesome($fa-var-chevron-left);
			margin-right: 0.5em;
		}
	}

	form { display: inline; }

	.skip {
		margin-right: 1em;
		text-decoration: underline;
	}
}

.form-action {
	button {
		@extend .button, .large;
		&:after {
			@include font-awesome("");
			margin-left: 0.5em;
		}
	}
}

.form-inline-actions {
    button { margin-right: 5px; }

    @include media($mobile) {
        button {
            font-size: 0.9em;
            display: block;
            width: 100%;
            margin-top: 10px;
        }
    }
}

.form-action-sml {
	padding-top: 2em;
	text-align: right;

	button {
		@extend .button;
	}
}

.form-compact {
	width: 80%;
	margin: 0 auto;
	padding: 2em;

	@include media($tablet) {
		width: 100%;
		padding: 0 0 2em 0;
	}
}

div[class*="action"],
#content-wrapper {
    button,
    input[type=submit],
    .form-button {
        border-radius: 2px;
        background-color: $color_dark;
        color: #fff !important;
        text-transform: uppercase;
        font-weight: bold;
        border: none;
        padding: 10px 20px;
        transition: background-color linear 150ms;
        display: inline-block;

        &.locked {
            &:after {
                background: url('../img/Spinner-1s-200px.svg') no-repeat center center;
                background-size: 130%;
                width: 20px;
                height: 20px;
                display: inline-block;
                vertical-align: -5px;
                margin-left: 10px;
                content: "";
            }
        }

        &:hover {
            background-color: #000 !important;
        }
        &:focus {
            outline: none;
        }

        &.small {
            font-size: 0.8em;
            padding: 5px 10px;
        }

        &.button-alt {
            background-color: $color-orange;
        }

        &.button-add {
            &:before {
                @include font-awesome($fa-var-plus);
                margin-right: 10px;
            }
        }

        &.next {
            background-color: $color-orange;
            &:after {
                margin-left: 0.5em;
                @include font-awesome($fa-var-chevron-right);
            }
            &:hover {
                background-color: darken($color-orange, 10%) !important;
            }
        }

        &.picker-button {
            border: none;
            color: #000000 !important;
            background: none;
            font-weight: normal;
            border-radius: 0;

            &:hover {
                background: #eeeeee !important;
                color: #000000 !important;
            }
        }

        &.button-link {
            background: none;
            padding: 0;
            font-weight: normal;
            text-transform: none;
            color: $color_orange !important;

            &:hover {
                background: none !important;
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}

.button {
    border-radius: 4px;
    background-color: $color-orange;
    color: white !important;
    padding: .5em .8em;
    text-transform: uppercase;
    display: inline-block;
    transition: background-color ease-in 150ms;
    border: none;

    &:after {
        @include font-awesome($fa-var-chevron-right);
        margin-left: 0.5em;
    }

    &.button-more-info {
        &:after { display: none; }
        &:before {
            @include font-awesome($fa-var-info-circle);
            margin-right: 10px;
        }
    }

    &.button-alternative {
        background: $color_green;
    }

    &.button-invoice {
        &:after { display: none; }
        &:before {
            @include font-awesome($fa-var-euro-sign);
            margin-right: 10px;
        }
    }

    &:hover {
        background-color: darken($color-orange, 10%);
    }

    &.large {
        padding: 0.5em 0.8em;
        font-size: 1.2em;
    }

    &.small {
        font-size: 0.8em;
        padding: .2em .6em;
    }

    &.block {
        display: block;
    }

    &.add {
        &:after {
            display: none;
        }
        &:before {
            margin-right: 0.5em;
            @include font-awesome($fa-var-plus);
        }
    }

    &.center {
        text-align: center;
    }

    @include media($mobile) {
        font-size: 1em !important;
    }
}

.g-recaptcha {
    margin-bottom: 1em;
}

.inline-form {
    display: flex;
    justify-content: space-between;

    input[type="number"] {
        display: inline-block;
        height: 100%;
        text-align: center;
        margin-right: 10px;
        max-width: 60px;
        background-color: $color_grey_light;
        border: none;
        border-radius: 3px;
        font-size: 1.1em;
        padding: 10px 0;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            opacity: 1;
            margin-right: 5px;
            cursor: pointer;
        }
    }
}
