.autoComplete_wrapper {
    display: inline-block;
    position: relative;
  }

  .autoComplete_wrapper > ul {
    position: absolute;
    max-height: 400px;
    overflow-y: auto;
    box-sizing: border-box;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    z-index: 900;
    list-style: none;
    background-color: #fff;
    border: 1px solid rgba(33, 33, 33, 0.07);
    box-shadow: 0 3px 6px rgba(149, 157, 165, 0.15);
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    outline: none;
    transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -webkit-transition: opacity 0.15s ease-in-out;
  }

  .autoComplete_wrapper > ul[hidden],
  .autoComplete_wrapper > ul:empty {
    display: block;
    opacity: 0;
    transform: scale(0);
  }

  .autoComplete_wrapper > ul > li {
    margin: 0.3rem;
    padding: 0.2rem 0.5rem;
    text-align: left;
    font-size: 0.9rem;
    color: #212121;
    background-color: rgba(255, 255, 255, 1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s ease;

    &.type-type {
      color: #999;

      em,
      strong {
        color: $color-text;
      }
    }

    &.no-result {
      padding: .5rem;

      span.more {
        display: block;
        font-size: .9rem;
      }
    }

    &:last-child.type-all {
      border-top: 1px solid rgba(33, 33, 33, 0.07);
    }
  }

  .autoComplete_wrapper > ul > li mark {
    background-color: transparent;
    color: black;
    font-weight: bold;
  }

  .autoComplete_wrapper > ul > li:hover {
    cursor: pointer;
    background-color: rgba(255, 122, 122, 0.15);
  }

  .autoComplete_wrapper > ul > li[aria-selected="true"] {
    background-color: rgba(255, 122, 122, 0.15);
  }

  @media only screen and (max-width: 600px) {
    .autoComplete_wrapper > input {
      width: 18rem;
    }
  }
